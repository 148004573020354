<script lang="ts" setup>
const emit = defineEmits<{
  (event: "mouseenter"): void;
  (event: "mouseleave"): void;
  (event: "focus"): void;
  (event: "blur"): void;
}>();

const props = withDefaults(
  defineProps<{
    type?: "button" | "submit" | "reset" | "link";
    disabled?: boolean;
  }>(),
  {
    type: "button",
    disabled: false,
  }
);

const { events: hoverEvents, class: hoverClass } = useHover(emit, props);
const { events: focusEvents, class: focusClass } = useFocus(emit, props);
</script>

<template>
  <button :type="props.type === 'link' ? 'button' : props.type"
    :class="['tw-button', `button-type--${props.type}`, props.disabled && 'is--disabled', hoverClass, focusClass]"
    :disabled="props.disabled" :tabindex="props.disabled ? -1 : 0" v-on="{ ...hoverEvents, ...focusEvents }">
    <slot />
  </button>
</template>

<style lang="scss">
@import "~/assets/styles/tw-variables.scss";
@import "~/assets/styles/tw-mixins.scss";

.tw-button {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: $tw-component--gap;
  padding: $tw-component--gap calc(#{$tw-component--gap} * 2);
  border: 1px solid transparent;
  transition: color, background-color, border-color, text-decoration-color;
  transition-duration: 0.15s;
  transition-timing-function: ease-in-out;
  user-select: none;
  cursor: pointer;
  @include tw-default-box-variant("box");
  @include tw-color-set(inherit, transparent, $gray);

  &.is--hovered {
    @include tw-color-set(null, null, $primary);
  }

  &.is--disabled {
    @include tw-color-set($white, $gray, $gray);
  }
}

.button-type--submit {
  @include tw-color-set($white, green);

  &.is--hovered {
    @include tw-color-set($white, mix(black, green, 10%), $primary);
  }

  &.is--disabled {
    @include tw-color-set(green, $gray, $gray);
  }
}

.button-type--reset {
  @include tw-color-set($white, red);

  &.is--hovered {
    @include tw-color-set($white, mix(black, red, 10%), $primary);
  }

  &.is--disabled {
    @include tw-color-set(red, $gray, $gray);
  }
}

.button-type--link {
  padding: 0;
  text-decoration-line: underline;
  @include tw-color-set(inherit, transparent, transparent, transparent);

  &.is--hovered {
    @include tw-color-set($primary, transparent, transparent, $primary);
  }

  &.is--disabled {
    text-decoration-line: line-through;
    @include tw-color-set($gray, transparent, transparent, $gray);
  }
}
</style>
